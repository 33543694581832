/* Header.css */

.header {
  width: 100%;
  background: linear-gradient(135deg, #2b5876, #4e4376);
  color: #fff;
  padding: 20px 0;
}

.header-container {
  max-width: 1200px; /* Adjust the width as needed */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Added padding to align with footer */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  margin-right: 10px;
}

.logo-text {
  font-size: 1em;
  color: #ffcc00;
}

.nav {
  display: flex;
  gap: 20px;
  transition: max-height 0.3s ease-out;
}

.nav-link {
  font-size: 1.2em;
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: color 0.3s;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #ffcc00;
  transition: width 0.3s;
}

.nav-link:hover {
  color: #ffcc00;
}

.nav-link:hover::after {
  width: 100%;
}

.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
}

.bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.bar2 {
  opacity: 0;
}

.bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    width: 100%;
  }

  .nav.nav-open {
    max-height: 300px; /* Adjust according to the number of links */
  }

  .nav-link {
    margin: 10px 0;
  }

  .menu-icon {
    display: flex;
  }
}
