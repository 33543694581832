/* Contact.css */

.contact-container {
  padding: 20px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #fff;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.contact-container h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffcc00;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.contact-info {
  font-size: 1.2em;
  margin-top: 20px;
}

.contact-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.contact-info .icon {
  margin-right: 10px;
  color: #ff6f61;
  font-size: 1.5em;
}
