/* Home.css */

.home-container {
  padding: 20px;
  background: linear-gradient(135deg, #2b5876, #4e4376);
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.home-header, .services-section, .why-choose, .contact-footer {
  margin-bottom: 40px;
}

.home-header h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #ffcc00;
  letter-spacing: 2px;
}

.home-header p {
  font-size: 0.9em;
}

.services-section h3, .why-choose h3, .contact-footer h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ffcc00;
}

.services-list, .benefits-list {
  list-style: none;
  padding: 0;
}

.services-list li, .benefits-list li {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: inline-block;
  align-items: center;
}

.services-list .icon, .benefits-list .icon {
  color: #ff6f61;
  margin-right: 10px;
}

.contact-footer p {
  font-size: 1.2em;
  line-height: 1.6;
}

.contact-footer .icon {
  color: #ff6f61;
  margin-right: 5px;
}
