/* Projects.css */

.projects-container {
  padding: 20px;
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.project-icon {
  font-size: 2em;
  color: #ff6f61;
  margin-bottom: 10px;
}

.project-name {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #ff6f61;
}

.project-domain {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #060270;
}

.project-description {
  font-size: 1em;
  color: #000000;
}
