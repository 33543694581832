body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

header {
  background-color: #4c96af;
  padding: 10px 0;
  text-align: center;
}

header h1 {
  margin: 0;
  color: #fff;
}

nav a {
  margin: 0 15px;
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

main {
  padding: 20px;
}

footer {
  background-color: #4c96af;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  position: relative;
  width: 100%;
  height: 1%;
  bottom: 0;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
}

.project-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 10px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
}

.project-item h3 {
  margin-top: 0;
}
