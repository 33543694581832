/* Footer.css */

.footer {
  width: 100%;
  background: linear-gradient(135deg, #2b5876, #4e4376);
  color: #fff;
  padding: 20px 0;
}

.footer-container {
  max-width: 1200px; /* Adjust the width to match the header */
  margin: 0 auto;
  text-align: center;
  padding: 0 20px; /* Added padding to align with header */
}

.footer p {
  margin: 10px 0;
  font-size: 1.2em;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.social-icon {
  color: #fff;
  font-size: 1.5em;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #ffcc00;
  transform: scale(1.2);
}

